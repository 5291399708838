/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import Header from '@/components/header/header'
import styles from './fill-information.module.scss'
import { formatTime, isIOS, isAndroid, validateIDNumber, Storage, debounce } from '@bihu/common-js'
import { Form, Input, Button, CascadePicker, Toast, Popup, Swiper } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import type { PickerValue, PickerValueExtend } from 'antd-mobile/es/components/picker'
import cityDate from '@/assets/js/city'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { OptionType } from '@/pages/fill-information/options'
import { TOKEN } from '@/constants/storage'
import {
  creditSesameOptions, // 芝麻分
  applyCreditOptions, // 申请额度
  jobTypeOptions, // 职业类型
  workDurationOptions, // 工作年限
  monthlyProfitOptions, // 月收入
  creditOptions, // 信用情况
  payoffFormOptions, // 工资发放方式
  socialSecurityOptions, // 社保缴纳选项
  accumulationFundOptions, // 公积金选项
  insurancePolicyOptions, // 保险保单选项
  carProductionOptions, // 车产选项
  realEstateOptions // 房产选项
} from './options'
import { getSex, getAge } from '@/utils/utils'
import DetainmentPopup from '@/pages/register/components/detainment-popup/detainment-popup'
import { getUserAsync, selectUserInfo } from '@/store/user'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import classNames from 'classnames'
import FormLoginModal from './components/form-login/form-login'
import activityApi from '@/apis/activity'
import FormChooseItem from './components/form-choose-item/form-choose-item' // 选择选项
import ChooseOption from './components/choose-option/choose-option' // 选择选项
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import indexApi, { FomrSubmitStatusParams } from '@/apis'
import { FqlPhoneModel } from '@/constants/common'
// 1.首先判断是非支持scrollTo，通过document.documentElement.style上是否有 scroll-behavior 这个属性。
// 2.对element添加scrollTo的方法。在原型对象上添加方法scrollTo
if (!('scrollBehavior' in document.documentElement.style)) {
  const Element = window.HTMLElement || window.Element

  Element.prototype.scrollTo = function() {
    let left = 0
    let top = 0
    if (arguments.length > 1) {
      left = arguments[0]
      top = arguments[1]
    } else {
      left = arguments[0].left
      top = arguments[0].top
    }
    this.scrollLeft = left
    this.scrollTop = top
  }
}

interface Props{ }

interface FormDataItem {
  name: string,
  key: string,
  value: string,
  options:OptionType[]
}

// 元信大额，填写资料
const FillInformation: React.FC<Props> = () => {
  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [search] = useSearchParams()
  const [from, setFrom] = useState<string>()
  const [visibleHead, setVisibleHead] = useState(true)

  const [progressPercentage, setProgressPercentage] = useState(50)
  const [estimatedAmount, setEstimatedAmount] = useState(5000)

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)
  const [isFirstSubmit, setIsFirstSubmit] = useState(true)

  const [token, setToken] = useState('')
  useEffect(() => {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    let ua = window.navigator.userAgent.toLowerCase()

    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (/MicroMessenger/i.test(ua) || !visibleHead) {
      document.title = '填写资料'
      setVisibleHead(false)
    }
    const code = search.get('adChannelCode')
    if (code) {
      localStorage.setItem('ad_channel_code', code)
      localStorage.setItem('hostName', '有钱')
    }
  }, [])

  const [userAgentStr, setUserAgentStr] = useState('')
  const [isHuaWeiPhone, setIsHuaWeiPhone] = useState<boolean>(false)
  const [versionType, setVersionType] = useState('')
  const [sexVisible, setSexVisible] = useState<boolean>(false)
  const [currentSwiperIndex, setCurrentSwiperIndex] = useState(0)
  const [swiperVisible, setSwiperVisible] = useState(true)
  const [locationSwiperIndex, setLocationSwiperIndex] = useState(0)
  const [hasEscalation, setHasEscalation] = useState<string[]>([])

  useEffect(() => {
    isHuawei()
  }, [])

  const isHuawei = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    setUserAgentStr(userAgent)
    if (userAgent.indexOf('huawei') !== -1) {
      setIsHuaWeiPhone(true)
      return
    }
    setIsHuaWeiPhone(false)
  }

  // 性别
  const sexArr = [
    {
      key: 1,
      name: '男',
      num: 1
    },
    {
      key: 2,
      name: '女',
      num: 2
    },
  ]

  const selectGender = (num: number) => {
    setGender(num)
    setSexVisible(false)
  }

  const [showWorkDuration, setShowWorkDuration] = useState(Boolean) //工作年限
  const [showMonthlyProfit, setShowMonthlyProfit] = useState(Boolean) //月收入
  const [showCreditInvestigation, setShowCreditInvestigation] = useState(Boolean) //信用情况

  // 步骤,当前选择到哪一步
  const [step, setStep] = useState(1)

  const [formDetail, setFormDetail] = useState<any>('')

  useEffect(() => {
    async function checkMobileLogin(reqId:string) {
      const res = await indexApi.checkMobileLogin({ reqId })
      // 如果以前注册过了有钱来，直接登录拿token
      if (res) {
        const loginRes = await activityApi.login({
          upStreamUnionLoginReqId: reqId,
        })
        handleLoginSuccess(loginRes)
      } else {
        // 如果第一次注册有钱来的用户，弹起登录协议弹窗，需用户手动确认再获取token
        formLoginModalRef.current && formLoginModalRef.current.init({ reqId })
      }
    }
    const formTemp = search.get('from')
    const urlToken = search.get('token')
    const reqId = search.get('reqId')

    if (formTemp) {
      setFrom(formTemp)
    }

    let tokenTemp = Storage.get(TOKEN)
    if (reqId) {
      // 处理OPPO联登逻辑
      checkMobileLogin(reqId)
    } else if (urlToken) {
      Storage.set(TOKEN, urlToken)
      setToken(urlToken)
      const url = window.location.href
      const updatedUrl = url.replace(/([?&])token=[^&]+(&|$)/, '$1')
      console.log('updatedUrl', updatedUrl)
      window.history.replaceState(null, 'null', updatedUrl)
    } else if (tokenTemp) {
      setToken(tokenTemp)
    }

    // 与app交互的函数
    window.getToken = function(t:string) {
      Storage.set(TOKEN, t)
      setToken(t)
    }
    try {
      if (isIOS) {
        window.webkit?.messageHandlers?.sendNativeMessage && window.webkit.messageHandlers.sendNativeMessage?.postMessage(JSON.stringify({
          action: 'getToken',
          data: {
            methodName: 'getToken'
          }
        }))
      } else if (isAndroid) {
        window.sendNativeMessage && window.sendNativeMessage?.postMessage(JSON.stringify({
          action: 'getToken',
          data: {
            methodName: 'getToken'
          }
        }))
      }
    } catch (error) {
      // console.log(error)
    }
  }, [])

  useEffect(() => {
    if (formDetail) {
      fillData(formDetail)
    }
  }, [formDetail, showWorkDuration, showMonthlyProfit, showCreditInvestigation])

  useEffect(() => {
    // 查看非必填表单项状态, 判断是否需要隐藏
    async function getFormItemShow() {
      const res:any = await indexApi.getFormItemShow()
      setShowWorkDuration(res.showWorkDuration === 1)
      setShowMonthlyProfit(res.showMonthlyProfit === 1)
      setShowCreditInvestigation(res.showCreditInvestigation === 1)
    }
    if (token) {
      getFormItemShow()
    }
  }, [token])

  // 获取用户资料
  useEffect(() => {
    async function getApplyStatus() {
      try {
        const res:any = await indexApi.getApplyStatus()
        indexApi.reportUserBehavior(1)
        let devicePlatForm:any = 3
        if (isAndroid) {
          devicePlatForm = 1
        }
        if (isIOS) {
          devicePlatForm = 2
        }
        indexApi.reportPhoneType(devicePlatForm)
        fillData(res.data)
        setFormDetail(res.data)
        if (res.data && res.data?.submitStatus === 1) {
          let submitData:FomrSubmitStatusParams = {
            adChannelCode
          }
          const phoneModel = sessionStorage.getItem('phoneModel')
          if (phoneModel) {
            submitData.phoneModel = phoneModel as FqlPhoneModel
          }
          // 获取上回提交信息
          const submitResult: any = await indexApi.getFomrSubmitStatus(submitData)
          if (submitResult.productName === null) {
            window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({
              isNotFormReq: true,
              rePageShowType: channelValue?.includes('fenqile') && isHuaWeiPhone ? 2 : 0,
              revisit: true
            }))}`)
            return
          }

          if (submitResult.thirdpartyType === 5) {
            window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(submitResult))}&adChannelCode=${adChannelCode}&isHuaWeiPhone=${isHuaWeiPhone}&applyLimit=${res.data?.applyLimit}`)
            return
          }
          // 跳转授权页
          let tempOptions = {
            adChannelCode,
            isHuaWeiPhone,
            applyLimit: res.data?.applyLimit,
            workCity: res.data?.workCity,
            rqResult: submitResult
          }
          window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
        } else if (res.data && res.data?.submitStatus === 10) {
          let submitData:FomrSubmitStatusParams = {
            adChannelCode
          }
          const phoneModel = sessionStorage.getItem('phoneModel')
          if (phoneModel) {
            submitData.phoneModel = phoneModel as FqlPhoneModel
          }
          // rematchResult: 1-不需要重新匹配 2-重新匹配不到结果 3-重新匹配到结果
          const reMatchResult:any = await indexApi.getFomrSubmitStatus(submitData)
          let tempOptions:any = {}
          // 如果重现匹配并且有结果，跳至动图页
          if (reMatchResult.rematchResult === 3) {
            // 携带授权数据跳转重新匹配结果页
            tempOptions = {
              adChannelCode,
              isHuaWeiPhone,
              applyLimit: res.data?.applyLimit,
              workCity: res.data?.workCity,
              rqResult: reMatchResult
            }
            window.location.replace(`/fill-information/rematch-waiting?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
            return
          }

          // 否则取上回的结果，跳至下载页
          tempOptions = JSON.parse(JSON.stringify(reMatchResult))
          tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
          tempOptions.rePageShowType = channelValue?.includes('fenqile') && isHuaWeiPhone ? 2 : 0
          tempOptions.revisit = true // 标记为再访
          window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
        }
      } catch (error) {
        console.log('error', error)
      }
    }

    if (token) {
      getApplyStatus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  // 是否显示弹窗
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

  // 返回
  const back = () => {
    navigate(-1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const formLoginModalRef: any = useRef()

  // 真实姓名
  const [realName, setRealName] = useState('')
  // 身份证号
  const [identity, setIdentity] = useState('')
  // 年龄
  const [age, setAge] = useState<any>()
  // 性别
  const [gender, setGender] = useState<any>('')


  // 表单
  const [form] = Form.useForm()

  // 检验身份证
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkIdentity = (_: any, value: string) => {
    if (value) {
      if (!validateIDNumber(value)) {
        Toast.show('身份证号不正确，请重新输入')
        return Promise.reject(new Error('身份证号不正确，请重新输入'))
      }
      return Promise.resolve()
    }
    Toast.show('请输入身份证号')
    return Promise.reject(new Error('请输入身份证号'))
  }

  // 城市
  const [cityVisible, setCityVisible] = useState(false)
  const [cityValue, setCityValue] = useState<(string|null)[]>([])
  const [cityShow, setCityShow] = useState('')
  const [haveCityData, setHaveCityData] = useState(false)
  const cityConfirm = (value: PickerValue[], extend:PickerValueExtend) => {

    const cityName = extend.items.map(item => item?.label).join()
    setCityShow(cityName)

    const v = value[0]
    if (v === null) {
      return false
    }

    setCityValue(value)
    setHaveCityData(true)

    form.validateFields(['livingCity'])
  }

  // 居住城市验证
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkLivingCity = (_: any, value: string) => {
    if (!cityShow) {
      Toast.show('请选择居住城市')
      return Promise.reject(new Error('请选择居住城市'))
    }
    return Promise.resolve()
  }


  // 是否展开
  const [initIsExpansion, setInitIsExpansion] = useState(true)

  // 芝麻分
  const [sesameCredit, setCreditSesame] = useState('')
  const creditSesameOnChange = (obj:OptionType) => {
    setCreditSesame(obj.value)
    if (obj.value) {
      setTimeout(() => {
        if (step < 9) {
          setStep(9)
        }
      }, 400)
    }
  }

  // 申请额度
  const [applyLimit, setApplyCredit] = useState('')
  const applyCreditOnChange = (obj:OptionType) => {
    setApplyCredit(obj.value)
    if (obj.value) {
      setTimeout(() => {
        if (step < 2) {
          setStep(2)
        }
      }, 400)
    }
  }

  // 职业类型
  const [job, setJob] = useState('')
  const applyJobChange = (obj:OptionType) => {
    setJob(obj.value)
    if (obj.value) {
      setTimeout(() => {
        if (step < 3) {
          setStep(3)
        }
      }, 400)
    }
  }

  // 工作年限
  const [workDuration, setWorkDuration] = useState('')
  const applyWorkDurationOnChange = (obj:OptionType) => {
    setWorkDuration(obj.value)
    scrollDown()
  }

  // 月收入
  const [monthlyProfit, setMonthlyProfit] = useState('')
  const applymonthlyProfitOnChange = (obj:OptionType) => {
    setMonthlyProfit(obj.value)
    scrollDown()
  }

  // 工资发放方式
  const [payoffForm, setPayoffForm] = useState('')
  const applypayoffFormOnChange = (obj:OptionType) => {
    setPayoffForm(obj.value)
    scrollDown()
  }

  // 信用情况
  const [creditInvestigation, setCreditConditions] = useState('')
  const creditConditionsOnChange = (values:any) => {
    setCreditConditions(values)
  }

  // 社保
  const [socialSecurity, setSocialSecurity] = useState('')
  const socialSecurityOnChange = (obj:OptionType) => {
    setSocialSecurity(obj.value)
    if (obj.value) {
      setTimeout(() => {
        if (step < 4) {
          setStep(4)
        }
      }, 400)
    }
  }

  // 公积金
  const [housingFund, setAccumulationFund] = useState('')
  const accumulationFundOnChange = (obj:OptionType) => {
    setAccumulationFund(obj.value)
    if (obj.value) {
      setTimeout(() => {
        if (step < 5) {
          setStep(5)
        }
      }, 400)
    }
  }

  // 保险保单
  const [insurance, setInsurancePolicy] = useState('')
  const insurancePolicyOnChange = (obj:OptionType) => {
    setInsurancePolicy(obj.value)
    if (obj.value) {
      setTimeout(() => {
        if (step < 6) {
          setStep(6)
        }
      }, 400)
    }
  }

  // 车产类型
  const [carProperty, setCarProduction] = useState('')
  const carProductionOnChange = (obj:OptionType) => {
    setCarProduction(obj.value)
    if (obj.value) {
      setTimeout(() => {
        if (step < 7) {
          setStep(7)
        }
      }, 400)
    }
  }

  // 房产类型
  const [houseProperty, setRealEstate] = useState('')
  const realEstateOnChange = (obj:OptionType) => {
    setRealEstate(obj.value)
    if (obj.value) {
      setTimeout(() => {
        if (step < 8) {
          setStep(8)
        }
      }, 400)
    }
  }

  interface assetInformationObjType{
    name: string,
    have: string,
    key: 'creditInvestigation' | 'payoffForm'
    options: OptionType[]
  }

  const assetInformationObj = [
    {
      name: '信用情况',
      have: creditInvestigation,
      key: 'creditInvestigation',
      options: creditOptions
    },
    {
      name: '工资发放方式',
      have: payoffForm,
      key: 'payoffForm',
      options: payoffFormOptions
    }
  ] as assetInformationObjType[]

  // 向下滚动
  const scrollDown = (distance = 250) => {
    const dom = document.getElementById('app')
    if (dom) {
      const currentScroll = dom.scrollTop || 0 // 已经被卷掉的高度
      setTimeout(() => {
        dom.scrollTo(0, currentScroll + distance)
      }, 0)
    }
  }

  // 回填数据
  const fillData = (info:any) => {
    // 回显除了最后一步的其它表单数据
    let hasLocationSwiperIndex = false
    const tempFormItemList = formItemList.map((item, index) => {
      if (item.key !== 'lastPage') {
        if (info[item.key] === null && !hasLocationSwiperIndex) {
          hasLocationSwiperIndex = true
          formSwiper.current?.swipeTo(index)
        } else {
          item.value = info[item.key] !== null ? info[item.key].toString() : null
        }
      }
      return item
    })
    if (!hasLocationSwiperIndex) {
      setCurrentSwiperIndex(9)
      // 避免swiper滚动，所以这里重新初始化swiper，设置swiper默认值
      setSwiperVisible(false)
      setTimeout(() => {
        setSwiperVisible(true)
      }, 100)
    }
    setFormItemList([...tempFormItemList])

    // 申请额度
    if (info.applyLimit !== null) {
      setApplyCredit(info.applyLimit.toString())
    }

    // 工作城市
    if (info.workCityCode || info.workCity) {
      setHaveCityData(true)
      setCityValue(info.workCityCode.split(','))
      setCityShow(info.workCity)
    }

    // 真实姓名
    if (info.realName !== null) {
      setRealName(info.realName)
    }
    // 身份证号
    if (info.identity !== null) {
      setIdentity(info.identity)
    }
    // 年龄
    if (info.identity !== null) {
      setAge(info.identity)
    }

    //有房产
    if (info.houseProperty !== null) {
      setRealEstate(info.houseProperty.toString())
    }
    // 车产
    if (info.carProperty !== null) {
      setCarProduction(info.carProperty.toString())
    }
    //有社保
    if (info.socialSecurity !== null) {
      setSocialSecurity(info.socialSecurity.toString())
    }
    // 有公积金
    if (info.housingFund !== null) {
      setAccumulationFund(info.housingFund.toString())
    }
    // 保险保单
    if (info.insurance !== null) {
      setInsurancePolicy(info.insurance.toString())
    }

    // 赋值给表单
    form.setFieldsValue({
      realName: info.realName,
      identity: info.identity,
      age: info.age,
      gender: info.gender
    })

    // 性别
    if (info.gender !== null) {
      setGender(info.gender)
    }

    // 职业类型
    if (info.job !== null) {
      setJob(info.job.toString())
    }

    // 工作年限
    if (showWorkDuration && info.workDuration !== null) {
      setWorkDuration(info.workDuration.toString())
    }
    // 月收入
    if (showMonthlyProfit && info.monthlyProfit !== null) {
      setMonthlyProfit(info.monthlyProfit.toString())
    }

    // 工资发放方式
    if (info.payoffForm !== null) {
      setPayoffForm(info.payoffForm.toString())
    }
    // 芝麻分
    if (info.sesameCredit !== null) {
      setCreditSesame(info.sesameCredit.toString())
    }
    // 信用情况
    if (showCreditInvestigation && info.creditInvestigation !== null) {
      setCreditConditions(info.creditInvestigation.toString())
    }
  }

  const getFillData = () => {
    const params:any = {
      realName, // 真实姓名
      workCityCode: cityValue.join(), // 城市
      workCity: cityShow, // 城市
      // sesameCredit, // 芝麻分
      job, // 职业类型
      payoffForm, // 工资发放方式
      applyLimit, // 申请额度

      houseProperty, //房产
      carProperty, //车产
      socialSecurity, //社保
      housingFund, //公积金
      insurance, //保险保单
    }
    if (from !== 'youxin') {
      // 芝麻分
      params.sesameCredit = sesameCredit
    }
    if (showWorkDuration) {
      // 工作年限
      params.workDuration = workDuration
    }
    if (showMonthlyProfit) {
      // 月收入
      params.monthlyProfit = monthlyProfit
    }
    if (showCreditInvestigation) {
      // 信用情况
      params.creditInvestigation = creditInvestigation
    }
    if (versionType === 'V2') {
      params.age = age
      params.gender = gender
    } else {
      params.identity = identity //身份证号
      if (validateIDNumber(identity)) {
        params.gender = getSex(identity) // 性别
        params.age = getAge(identity) // 年龄
      }
    }
    return params
  }

  // 保存上传
  const save = async(params:any) => {
    localStorage.setItem('fillInformation', JSON.stringify(params))
    try {
      await indexApi.tempSave(params)
    } catch (err) {
      console.log(err)
    }
  }
  // 对表单保存进行截流
  const debounceSave = useMemo(() => debounce(save, 2000, false), [])

  const handleLoginSuccess = (res:any) => {
    Storage.set(TOKEN, res.token)
    setToken(res.token)
  }

  // 将身份证输入单独拎出来监控
  useEffect(() => {
    if (identity) {
      if (validateIDNumber(identity)) {
        debounceSave(getFillData())
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity])

  // 表单各项变化监控(身份证除外)
  useEffect(() => {
    if (realName || age || gender || cityShow || sesameCredit || creditInvestigation || applyLimit || houseProperty || carProperty || socialSecurity
      || housingFund || insurance || job || workDuration || monthlyProfit || payoffForm) {
      debounceSave(getFillData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    realName,
    age,
    gender,
    cityShow,
    sesameCredit,
    creditInvestigation,
    applyLimit,
    houseProperty,
    carProperty,
    socialSecurity,
    housingFund,
    insurance,
    job,
    workDuration,
    monthlyProfit,
    payoffForm
  ])

  const handleScrollTop = () => {
    const dom = document.getElementById('app')
    if (dom) {
      dom.scrollTo(0, 0)
    }
  }

  // 提交
  const confirm = () => {
    if (isFirstSubmit) {
      indexApi.reportUserBehavior(2)
      setIsFirstSubmit(false)
    }
    if (!realName) {
      handleScrollTop()
      return Toast.show('请输入真实姓名')
    }

    if (!/^[\u4e00-\u9fa5]+$/.test(realName)) {
      return Toast.show('真实姓名不能含有非中文字符')
    }

    if (versionType === 'V2') {
      if (!age) {
        handleScrollTop()
        return Toast.show('请输入年龄')
      }
      if (!gender) {
        handleScrollTop()
        return Toast.show('请选择性别')
      }
    }

    form.validateFields().then(obj => {
      if (!applyLimit) {
        return Toast.show('请选择申请额度')
      }

      if (!job) {
        return Toast.show('请选择职业类型')
      }

      if (!socialSecurity) {
        return Toast.show('请选择社保')
      }

      if (!housingFund) {
        return Toast.show('请选择公积金')
      }

      if (!insurance) {
        return Toast.show('请选择保险保单信息')
      }

      if (!carProperty) {
        return Toast.show('请选择车产')
      }

      if (!houseProperty) {
        return Toast.show('请选择房产')
      }

      if (!workDuration && showWorkDuration) {
        return Toast.show('请选择工作年限')
      }

      if (!monthlyProfit && showMonthlyProfit) {
        return Toast.show('请选择月收入')
      }

      if (!sesameCredit && from !== 'youxin') {
        return Toast.show('请选择芝麻分')
      }

      if (!creditInvestigation && showCreditInvestigation) {
        return Toast.show('请选择信用情况')
      }

      if (!payoffForm) {
        return Toast.show('请选择工资发放方式')
      }

      const params:any = {
        realName, // 真实姓名
        workCityCode: cityValue.join(), // 城市
        workCity: cityShow, // 城市
        job, // 职业类型
        payoffForm, // 工资发放方式
        applyLimit, // 申请额度
        adChannelCode,
        isHuaWeiPhone,
        houseProperty, //房产
        carProperty, //车产
        socialSecurity, //社保
        housingFund, //公积金
        insurance, //保险保单
      }
      if (versionType === 'V2') {
        if (age < 18) {
          return Toast.show('年龄需要大于18')
        }
        if (age > 99) {
          return Toast.show('年龄需要小于99')
        }
        params.age = age
        params.gender = gender
      } else {
        params.identity = identity //身份证号
        if (validateIDNumber(identity)) {
          params.gender = getSex(identity) // 性别
          params.age = getAge(identity) // 年龄
        }
      }

      if (from !== 'youxin') {
        // 芝麻分
        params.sesameCredit = sesameCredit
      }

      if (showWorkDuration) {
        // 工作年限
        params.workDuration = workDuration
      }

      if (showMonthlyProfit) {
        // 月收入
        params.monthlyProfit = monthlyProfit
      }

      if (showCreditInvestigation) {
        // 信用情况
        // params.credit = credit
        params.creditInvestigation = creditInvestigation
      }

      localStorage.setItem('fillInformation', JSON.stringify(params))
      console.log(params)

      try {
        setBtnLoading(true)
        // 姓名、身份证二要素验证
        // await indexApi.identityNameVerify({ realName, identity, adChannelCode })
        window.location.href = `/fill-information/waiting-result?options=${encodeURIComponent(JSON.stringify(params))}&sourceType=submit`
        setTimeout(() => {
          setBtnLoading(false)
        }, 100)
      } catch (error) {
        setBtnLoading(false)
      }
    })
      .catch(error => {
        // eslint-disable-next-line no-console
        // console.log('error', error)
        const dom = document.getElementById('app')
        if (dom) {
          dom.scrollTo(0, 0)
        }
      })
  }


  const isHave = (key: assetInformationObjType['key'], have: number | string):void => {
    switch (key) {
      case 'creditInvestigation':
        creditConditionsOnChange(String(have))
        break
      case 'payoffForm':
        setPayoffForm(String(have))
        break
      default:
        break
    }
  }

  const formSwiper = useRef<SwiperRef>(null)


  const onChangeFormItem = (obj:OptionType, currentItem: any, nextIndex: any) => {
    // eslint-disable-next-line default-case
    switch (currentItem.key) {
      case 'applyLimit':
        applyCreditOnChange(obj)
        break
      case 'job':
        applyJobChange(obj)
        break
      case 'socialSecurity':
        socialSecurityOnChange(obj)
        break
      case 'housingFund':
        accumulationFundOnChange(obj)
        break
      case 'insurance':
        insurancePolicyOnChange(obj)
        break
      case 'carProperty':
        carProductionOnChange(obj)
        break
      case 'houseProperty':
        realEstateOnChange(obj)
        break
      case 'sesameCredit':
        creditSesameOnChange(obj)
        break
    }

    formSwiper.current?.swipeTo(nextIndex)
  }


  useEffect(() => {
    // 如果是渠道特殊值有取消按钮，则在这里进行表单项的修改
    let items = [
      {
        name: '您想借多少？',
        key: 'applyLimit',
        value: '',
        options: channelValue?.includes('cancleFillBtn') ? applyCreditOptions.filter(item => item.value !== '1') : applyCreditOptions,
      },
      {
        name: '您的职业是什么？',
        key: 'job',
        value: '',
        options: jobTypeOptions,
      },
      {
        name: '您在本地社保缴纳多长时间？',
        key: 'socialSecurity',
        value: '',
        options: socialSecurityOptions,
      },
      {
        name: '您在本地公积金缴纳多长时间？',
        key: 'housingFund',
        value: '',
        options: accumulationFundOptions,
      },
      {
        name: '您的保险保单信息？',
        key: 'insurance',
        value: '',
        options: insurancePolicyOptions,
      },
      {
        name: '您名下是否有车？',
        key: 'carProperty',
        value: '',
        options: carProductionOptions,
      },
      {
        name: '您名下是否有房？',
        key: 'houseProperty',
        value: '',
        options: realEstateOptions,
      },
      {
        name: '请选择您的芝麻分范围',
        key: 'sesameCredit',
        value: '',
        options: creditSesameOptions,
      },
      {
        name: '',
        key: 'lastPage',
        value: '',
        options: [],
      },
    ]
    setFormItemList(items)
  }, [channelValue])

  // 单页表单项列表
  const [formItemList, setFormItemList] = useState<FormDataItem[]>([])

  const formItems = formItemList.map((formItem, formIndex) => (
    <Swiper.Item key={formIndex}>
      {
        formItem.key === 'lastPage' ? <>
          <div className={styles.lastBox}>
            <Form
              form={form}
              layout="horizontal"
              mode="card"
            >
              <div className={styles.lastBoxItem}>
                <div className={styles.formHeader}>
                  <img className={styles.formHeaderIcon} src={require('@imgs/information/safe-auth.png')} />
                  <div className={styles.formHeaderRight}>
                    <div className={styles.formHeaderTitle}>官方认证，保护您的隐私安全</div>
                    <div className={styles.formHeaderSubTitle}>您的身份证号码仅作真实性验证，我方不会另作他用</div>
                  </div>
                </div>
                <div className={styles.formBox}>
                  <Form.Item
                    label="真实姓名"
                    name="realName"
                    rules={[
                      {
                        validateTrigger: 'onblur',
                        required: true
                      },
                    ]}
                  >
                    <Input maxLength={4} value={realName} placeholder="请输入真实姓名" onBlur={val => {
                      setRealName(val.target.value)
                    }} />
                  </Form.Item>
                  <div className={styles.line}></div>
                  <Form.Item
                    label="身份证号"
                    name="identity"
                    rules={[
                      {
                        validateTrigger: 'onBlur',
                        validator: checkIdentity
                      }
                    ]}
                  >
                    <Input
                      placeholder="请输入身份证号"
                      maxLength={18}
                      value={identity}
                      onBlur={val => {
                        setIdentity(val.target.value)
                      }} />
                  </Form.Item>
                </div>
              </div>
              <div className={styles.lastBoxItem}>
                <div className={styles.formHeader}>
                  <img className={styles.formHeaderIcon} src={require('@imgs/information/address.png')} />
                  <div className={styles.formHeaderRight}>
                    <div className={styles.formHeaderTitle}>当前所在地址</div>
                    <div className={styles.formHeaderSubTitle}>请选择当前您所在的城市，提高匹配效率</div>
                  </div>
                </div>
                <div className={styles.formBox}>
                  <Form.Item
                    label="当前所在城市"
                    name="livingCity"
                    trigger="onConfirm"
                    className={styles.city}
                    rules={[
                      {
                        validator: checkLivingCity
                      }
                    ]}
                    onClick={e => {
                      setCityVisible(true)
                    }}>
                    <>
                      <div className={styles.row} >
                        <Input placeholder="请选择城市" readOnly value={cityShow} />
                      </div>
                      <CascadePicker
                        options={cityDate}
                        visible={cityVisible}
                        onClose={() => {
                          setCityVisible(false)
                        }}
                        value={cityValue}
                        onConfirm={cityConfirm}
                      />
                    </>
                  </Form.Item>
                </div>
              </div>

              <div className={styles.lastBoxItem}>
                <div className={styles.formHeader}>
                  <img className={styles.formHeaderIcon} src={require('@imgs/information/note.png')} />
                  <div className={styles.formHeaderRight}>
                    <div className={styles.formHeaderTitle}>您的逾期情况</div>
                    <div className={styles.formHeaderSubTitle}>逾期情况只作为您的资方匹配，请您认真选择</div>
                  </div>
                </div>
                <div className={styles.formBox}>
                  <div className={styles.content}>
                    {
                      assetInformationObj.map(item => {
                        return (
                          <div className={classNames([styles.selectBox, styles[item.key]])} key={item.key}>
                            <div className={styles.left}>{item.name}</div>
                            <div className={styles.right}>
                              {item.options.map(option => (
                                <span key={option.label} className={classNames(item.have === option.value && styles.active)} onClick={() => isHave(item.key, option.value)}>{option.label}</span>
                              ))}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  {
                    showWorkDuration && <div className={styles.content}>
                      <ChooseOption
                        name="工作年限"
                        onChange={applyWorkDurationOnChange}
                        options={workDurationOptions}
                        initIsExpansion={initIsExpansion}
                        firstShowBottom={true}
                        value={workDuration}
                      />
                    </div>
                  }
                  {
                    showMonthlyProfit && <div className={styles.content}>
                      <ChooseOption
                        name="月收入"
                        onChange={applymonthlyProfitOnChange}
                        options={monthlyProfitOptions}
                        initIsExpansion={initIsExpansion}
                        firstShowBottom={true}
                        value={monthlyProfit}
                      />
                    </div>
                  }
                  {/* <div className={styles.content}>
                    <ChooseOption
                      name="工资发放方式"
                      onChange={applypayoffFormOnChange}
                      options={payoffFormOptions}
                      initIsExpansion={initIsExpansion}
                      firstShowBottom={true}
                      value={payoffForm}
                    />
                  </div> */}
                </div>
              </div>
            </Form>
            <Button block loading={btnLoading} loadingText="机构匹配中" className={styles.btn} shape="rounded" color="primary" onClick={confirm}>
              提交借款
            </Button>
          </div>
        </> : <>
          <FormChooseItem
            name={formItem.name}
            onChange={obj => onChangeFormItem(obj, formItem, formIndex + 1)}
            options={formItem.options}
            initIsExpansion={initIsExpansion}
            value={formItem.value}
          />
        </>
      }
    </Swiper.Item>
  ))

  const onChangeSwiper = (index:number) => {
    // 当前下标 index
    setStep(index + 1)
  }

  // 设置第二屏步骤
  useEffect(() => {
    if (step) {
      if (step === 1) {
        setProgressPercentage(50)
        setEstimatedAmount(5000)
      }
      if (step === 2) {
        setProgressPercentage(56)
        setEstimatedAmount(12000)
      }
      if (step === 3) {
        setProgressPercentage(62)
        setEstimatedAmount(19000)
      }
      if (step === 4) {
        setProgressPercentage(68)
        setEstimatedAmount(25000)
      }
      if (step === 5) {
        setProgressPercentage(74)
        setEstimatedAmount(31000)
      }
      if (step === 6) {
        setProgressPercentage(80)
        setEstimatedAmount(38000)
      }
      if (step === 7) {
        setProgressPercentage(86)
        setEstimatedAmount(42000)
      }
      if (step === 8) {
        setProgressPercentage(94)
        setEstimatedAmount(49000)
      }
      if (step === 9) {
        if (!haveCityData) {
          openLocation()
        }
        setProgressPercentage(100)
        setEstimatedAmount(55000)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  // 获取定位
  const openLocation = () => {
    let isHandleCopyFunction = true
    let hasHandleCopyFunction = false
    // 开始执行位置授权
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          isHandleCopyFunction = false
          // 获取到的地理位置 position
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(position.coords.latitude, position.coords.longitude)
        },
        error => {
          isHandleCopyFunction = false
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(null, null)
        },
      )
    } else {
      getUserLocation(null, null)
    }
  }
  const getUserLocation = async(latitude:any, longitude:any) => {
    let params = latitude ? {
      location: `${latitude},${longitude}`,
      adChannelCode,
      coordtype: 'wgs84ll'
    } : {
      adChannelCode,
    }
    let res:any = await activityApi.reverseGeo(params)
    console.log(res)
    // res数据格式 例：{ province: 'xx', city: 'xx' }
    let currentProvince:any = cityDate.find(item => {
      return item.label === res.data?.province
    })
    let currentCity:any = ''
    if (currentProvince && currentProvince.children) {
      currentCity = currentProvince.children.find((cityItem:any) => {
        return cityItem.label === res.data?.city
      })
    }
    // 筛选出来的城市 currentProvince, currentCity
    if (currentProvince && currentCity && !haveCityData) {
      const workCityCode:any = `${currentProvince.value},${currentCity.value}`
      const workCity:any = `${currentProvince.label},${currentCity.label}`
      setCityValue(workCityCode.split(','))
      setCityShow(workCity)
      setHaveCityData(true)
    }
  }

  useEffect(() => {
    localStorage.removeItem('productBack')
  }, [])

  return (
    <div className={styles.fillInformation} style={{ paddingTop: visibleHead ? 'auto' : '0px' }}>
      {
        visibleHead ? (<Header backCallBack={back} />) : (<></>)
      }

      <div className={styles.headerBox}>
        <div className={styles.progressBar} style={{ width: `${progressPercentage}%` }}>
          <div className={styles.progressPercentage} style={{ right: progressPercentage === 100 ? 0 : '-10px' }}>{progressPercentage}%</div>
        </div>
        <div className={styles.progressInfo}>
          <div className={styles.progressInfoLeft}>
            <div className={styles.progressLimit}>{ estimatedAmount }</div>
            <div className={styles.progressText}>预估额度（元）</div>
          </div>
          <div className={styles.progressInfoRight}>
            <span className={styles.currentStepNum}>{step}</span>
            <span className={styles.totalStepNum}>/9</span>
          </div>
        </div>
      </div>
      {
        swiperVisible && <Swiper
          ref={formSwiper}
          indicator={() => null}
          defaultIndex={currentSwiperIndex}
          onIndexChange={onChangeSwiper}
        >
          {formItems}
        </Swiper>
      }
    </div>
  )
}

export default FillInformation