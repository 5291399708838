/* eslint-disable no-shadow */
// 接口返回全局状态码
export const RESPONSE_CODE = {
  SUCCESS: 200, // 接口调用成功
  TOKEN_OVERDUE: 401, // token 失效，登录过期
  NO_PERMISSION: 403, // 无访问资源权限
  TOKEN_ERROR: 1005, // token 无效
  OTHER_LOGIN: 1006, // 账号被其他人登录
  ACCOUNT_FORBIDDEN: 1007, // 账号被禁用
  LOGIN_PSW_NAME_ERROR: 1000, // 登录名或密码错误
}

export const ENTRANCE_TYPE = {

  /** 首页中插 */
  HOME_PAGE_INSERTION: 1,

  /** 首页弹窗 */
  HOME_PAGE_POPUP: 2,

  /** 开屏 */

  THE_TAIL: 3,

  /** 顶部推荐 */

  TOP_RECOMMENDATIONS: 4,

  /** 首页列表 */

  HOME_PAGE_LIST: 5,

  /** 产品列表 */

  LIST_OF_PRODUCTS: 6,

  /** 随机推荐 */

  RANDOM_RECOMMENDATION: 7
} as const

/** 分期乐机型字段 */
export enum FqlPhoneModel { //
  /** 苹果 */
  iphone = '01', //
  /** 华为 */
  HuaWei = '02', //
  /** 荣耀 */
  RongYao = '03', //
  /** oppo */
  oppo = '04', //
  /** vivo */
  vivo = '05', //
  /** 小米 */
  XiaoMi = '06', //
  /** 三星 */
  Samsung = '07', //
  /** 1+ */
  One = '08', //
  /** 其他 */
  Other = '09',
}