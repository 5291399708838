/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
// 广告结果页
import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import styles from './ios-skin.module.scss'
import classNames from 'classnames'
import { Button, Swiper } from 'antd-mobile'
import { isIOS } from '@bihu/common-js'
import indexApi from '@/apis'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import JointLoginPopup from '@/components/joint-login-popup/joint-login-popup'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import Icon from '@/components/icon/icon'
import { useSearchParams } from 'react-router-dom'

interface Props {
  productName?: '', // 产品名字
  applyStatus?: number, // hold单状态
  revisit?: any, // 二次回访结果页
  onChange: () => void, // 改变事件
  isMultiProduct:boolean // 判断是否为多产品
}

const IosSkin: React.FC<Props> = props => {
  const [search] = useSearchParams()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const { productName, onChange, applyStatus, revisit, isMultiProduct } = props
  const [banners, setBanners] = useState<any>([])

  // 获取产品列表
  useEffect(() => {
    async function homeProductList() {
      const res:any = await indexApi.topRecommend()
      const tempBanners = res.map((item:any) => {
        item.lendingRateString = Math.round(Math.random() * 6 + 88)
        return item
      })
      console.log(res)

      setBanners([...tempBanners])
    }
    homeProductList()
  }, [])

  const [hotProductList, setHotProductList] = useState<any>([])
  // 获取产品列表
  useEffect(() => {
    async function homeProductList() {
      const res:any = await indexApi.homeProductList({ appH5: 2 })
      let tempProductList = [...res.hotRecommends, ...res.jumboLoanRecommends, ...res.microfinanceRecommends]
      setHotProductList(tempProductList)
    }
    homeProductList()
  }, [])

  // 点击产品
  const applyProduct = async(item: any, entranceType: number) => {

    let res:any = await indexApi.applyFor({
      productId: item.id,
      entranceType,
      appH5: 2
    })

    if (res.unionLoginFlag === 1) {
      const params = {
        productId: item.id,
        productName: item.productName,
        productLogo: item.productLogo,
        h5Protocols: res.h5Protocols,
        loansLimitMax: item.loansLimitMax,
        entranceType,
        appH5: 2
      }
      // 打开产品联登授权弹窗
      JointLoginModalRef.current?.init(params)
      return
    }

    window.location.href = res.thirdpartyTargetUrl
  }

  const formSwiper = useRef<SwiperRef>(null)
  const [currentSwiperIndex, setCurrentSwiperIndex] = useState(0)
  const [exposureProductIds, setExposureProductIds] = useState<any>([])

  const onChangeSwiper = (index:number) => {
    setCurrentSwiperIndex(index)
    // 判断是否已经曝光过
    const isExposure = exposureProductIds.find((item:any) => item === banners[index].id)
    if (isExposure) {
      return
    }
    exposureProductIds.push(banners[index].id)
    setExposureProductIds([...exposureProductIds])
    // 上报产品曝光
    indexApi.reportProductExposure({
      productId: banners[index].id,
      entranceType: 4,
      appH5: 2
    })
  }

  // 千位分隔符
  const numFormat = (num:number) => {
    if (!num) {
      return
    }
    let res = num.toString().replace(/\d+/, n => { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, $1 => {
        return `${$1},`
      })
    })
    return res
  }

  const JointLoginModalRef: any = useRef()

  const swiperItems = banners.map((swiperItem:any, swiperIndex:any) => (
    <Swiper.Item key={swiperIndex}>
      <div className={styles.swiperBoxItem} onClick={() => applyProduct(swiperItem, 4)}>
        <div className={styles.swiperItemProduction}>
          <img className={styles.swiperItemProductionLogo} src={swiperItem.productLogo} alt="productLogo" />
          <div className={styles.swiperItemProductionName}>{swiperItem.productName}</div>
          <div className={styles.swiperItemProductionLine}></div>
          <div className={styles.swiperItemProductionTips}>因您资质良好，为您推荐</div>
        </div>
        <div className={styles.swiperItemText1}>最高可借(元)</div>
        <div className={styles.swiperItemText2}>{numFormat(swiperItem.loansLimitMax)}</div>
        <div className={styles.LoanBox}>
          <img className={styles.LoanBoxBg} src={require('@imgs/ios-skin/LoanBox.png')} alt="LoanBox" />
          <span className={styles.LoanBoxText}>今日放款率{swiperItem.lendingRateString}%</span>
        </div>
        <Button block className={styles.btn} shape="rounded" color="primary">
          我要申请
        </Button>
      </div>
    </Swiper.Item>
  ))

  // 文字logo
  const youqianLogoText = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <Icon type="jufu-haier-text-logo" className={styles.youqianTextLogoSvg}></Icon>
      case channelValue?.includes('jurongyi'):
        return <Icon type="jufu-jurongyi-text-logo" className={styles.youqianTextLogoSvg}></Icon>
      case channelValue?.includes('jufu'):
        return <img className={styles.youqianLogoText} src={require('@imgs/jufu/title.png')} alt="youqian-text" />
      default:
        return <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
    }
  }, [channelValue])

  // 结果页下载logo
  const youqianDownloadLogo = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <img className={styles.downloadBarLogo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jufuLogo.jpg')} alt="logo" />
      case channelValue?.includes('jurongyi'):
        return <img className={styles.downloadBarLogo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jurongyiLogo.png')} alt="logo" />
      default:
        return <img className={styles.downloadBarLogo} src={require('@imgs/youqianqianbao-logo.png')} alt="logo" />
    }
  }, [channelValue])

  // 结果页logo
  const youqianLogo = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <img className={styles.logo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jufuLogo.jpg')} alt="logo" />
      case channelValue?.includes('jurongyi'):
        return <img className={styles.logo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jurongyiLogo.png')} alt="logo" />
      default:
        return <img className={styles.logo} src={require('@imgs/youqianqianbao-logo.png')} alt="logo" />
    }
  }, [channelValue])

  return (
    <div className={styles.iosSkin}>
      {
        !channelValue?.includes('fqlPhoneModel') && (isIOS || revisit) && <div className={styles.downloadBar} onClick={onChange}>
          <div className={styles.downloadAppLeft}>
            {youqianDownloadLogo}
            <span>查看更多产品</span>
          </div>
          <div className={styles.downloadBtn}>下载APP</div>
        </div>
      }
      <div className={styles.main} style={{
        backgroundImage:
        channelValue?.includes('jufu') ? `url(${require('@imgs/jufu/result-backgroud.png')})`
          : `url(${require('@imgs/ios-skin/head-bg.png')})`
      }}>
        {
          channelValue?.includes('mjy') || channelValue?.includes('hbjf') ? <></> : <div className={styles.logoBox}>
            {youqianLogo}
            <div className={styles.logoBoxRight}>
              {youqianLogoText}
              <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
            </div>
          </div>
        }
        {
          productName && <div className={styles.applyResultBox}>
            <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
            {
              applyStatus === 8 ? <>
                <div className={styles.applyResultBoxRight}>
                  <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{!isMultiProduct && productName}</span>匹配成功！</div>
                  <div className={styles.applyResultBoxText2}>审核人员将在<span className={styles.highlight}>工作时间来电</span>完成审核</div>
                </div>
              </> : <>
                <div className={styles.applyResultBoxRight}>
                  <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{!isMultiProduct && productName}</span>申请成功！</div>
                  <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
                </div>
              </>
            }
          </div>
        }
        <div className={styles.containers}>
          {
            !productName && <div className={styles.noApplyResultBox}>
              <img className={styles.noApplyResultBoxIcon} src={require('@imgs/ios-skin/success-2.png')} alt="success-2" />
              <span className={styles.noApplyResultBoxText}>预审通过</span>
            </div>
          }
          <div className={styles.swiperBox}>
            <Swiper
              ref={formSwiper}
              indicator={() => null}
              loop
              autoplay
              autoplayInterval={4000}
              defaultIndex={currentSwiperIndex}
              onIndexChange={onChangeSwiper}
            >
              {swiperItems}
            </Swiper>
          </div>
          <div className={styles.indicatorBox}>
            {
              banners.map((swiperItem:any, swiperIndex:any) => (
                <div key={swiperIndex} className={classNames(styles.indicatorBoxItem, swiperIndex === currentSwiperIndex ? styles.active : '')}></div>
              ))
            }
          </div>
          <div className={styles.productBox}>
            <div className={styles.title}>为您推荐-待申请</div>
            <div className={styles.productListBox}>
              {
                hotProductList.map((item:any, index: number) => {
                  return <div key={index} className={classNames(styles.card, 'productCard')} onClick={() => applyProduct(item, 6)}>
                    <div className={styles.left}>
                      <div className={styles.productLimit}>￥{ numFormat(item.loansLimitMax) }</div>
                      <div className={styles.productCompanyBox}>
                        <img className={styles.productCompanyIcon} src={item.productLogo} alt="productLogo" />
                        <span className={styles.productCompanyName}>额度由{item.productName}提供</span>
                      </div>
                    </div>
                    <div className={classNames(styles.apply)}>立即申请</div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
        <div className={styles.footerBox}>
          <div className={styles.titleBox}>
            <img className={styles.titleLine} src={require('@imgs/ios-skin/title-line-left.png')} alt="title-line-left" />
            <div className={styles.titleText}>正规产品 安全信赖</div>
            <img className={styles.titleLine} src={require('@imgs/ios-skin/title-line-right.png')} alt="title-line-left" />
          </div>
          <div className={styles.sortBox}>
            <div className={styles.sortBoxItem}>
              <img className={styles.sortBoxImg} src={require('@imgs/ios-skin/sort-1.png')} alt="img" />
              <div className={styles.sortBoxItemText}>选择推荐产品</div>
            </div>
            <img className={styles.sortBoxArrow} src={require('@imgs/ios-skin/sort-arrow.png')} alt="img" />
            <div className={styles.sortBoxItem}>
              <img className={styles.sortBoxImg} src={require('@imgs/ios-skin/sort-2.png')} alt="img" />
              <div className={styles.sortBoxItemText}>匹配机构</div>
            </div>
            <img className={styles.sortBoxArrow} src={require('@imgs/ios-skin/sort-arrow.png')} alt="img" />
            <div className={styles.sortBoxItem}>
              <img className={styles.sortBoxImg} src={require('@imgs/ios-skin/sort-3.png')} alt="img" />
              <div className={styles.sortBoxItemText}>提现放款</div>
            </div>
          </div>
        </div>
        <div className={styles.footerText}>
          郑重声明:平台只提供贷款咨询和推荐服务，放款由机构进行，所有贷款申请在未成功贷款前绝不收取任何费用，为了保证您的资全安全，请不要相信任何要求您支付费用的信息、邮件电话等不实信息贷款额度，利率，放款时间以实际审批结果为准。
        </div>
      </div>

      <JointLoginPopup ref={JointLoginModalRef}></JointLoginPopup>
    </div>
  )
}

export default IosSkin
