/** 项目初始化任务处理 */
import { RootState } from '@/store'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { FqlPhoneModel } from '@/constants/common'
export const useInitControl = () => {
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const [search] = useSearchParams()
  const fqlInitGetPhoneModel = () => {
    const phoneModel = search.get('phoneModel')
    let isOppoVivo = phoneModel === FqlPhoneModel.oppo || phoneModel === FqlPhoneModel.vivo
    phoneModel && channelValue?.includes('fqlPhoneModel') && sessionStorage.setItem('phoneModel', phoneModel)
    if (channelValue?.includes('fqlPhoneModel') && isOppoVivo && phoneModel) {
      sessionStorage.setItem('fql_phoneModel', 'fql_phoneModel')
    }
  }
  useEffect(() => {
    fqlInitGetPhoneModel()
  }, [channelValue])
}